import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function (d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/uk/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/moisturising/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/moisturising/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/moisturising/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/moisturising/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt="Intensely moisturising sun protection"
                    src="/assets/images/moisturising-uk.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/moisturising-mobile-uk.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> MOISTURISING
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Intensely moisturising sun protection</h3>
                      <p>
                        If you love the sun and want to take care of your skin,
                        our PIZ BUIN<sup>®</sup> MOISTURISING range is just what
                        you need. Advanced UVA/UVB sun protection filters help
                        protect the skin from the sun and, combined with
                        effective anti-oxidants, help prevent sun induced
                        premature skin ageing. A unique hydrating complex
                        provides intense moisturisation to help maintain the
                        skin’s natural hydration level while tanning.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="sun-lotion" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-sun-lotion-50spf-200ml.jpg" alt="Intensely moisturising sun protection" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SUN LOTION</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          The non-greasy, non-sticky formula of the PIZ BUIN
                          <sup>®</sup> MOISTURISING Sun Lotion intensively
                          moisturises your skin while tanning under the sun,
                          leaving it smooth and silky soft all day.
                          <br />
                          PIZ BUIN<sup>®</sup> MOISTURISING Sun Lotion is sweat
                          and water resistant.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                        <h2>Available in sizes</h2>
                        <p>
                          200ml, 200 and 100ml duo and 400ml (PIZ BUIN
                          <sup>®</sup> MOISTURISING Sun Lotion SPF 10)
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" alt="ADVANCED UVA/UVB SUN FILTER SYSTEM" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    The Feverfew plants origin is in the
                                    mountain scrub and rocky soil of the Balkan
                                    Peninsula but now it can be found in several
                                    areas of the world. The PIZ BUIN<sup>®</sup>
                                    parent company, Kenvue, has
                                    patented this innovative extract for use in
                                    skincare, applying a new extraction process
                                    that keeps the active ingredients and
                                    eliminates unwanted components that could
                                    cause allergies.
                                  </p>
                                  <p>
                                    How does FEVERFEW PFE<sup>™</sup> help your
                                    skin?
                                  </p>
                                  <p>
                                    FEVERFEW PFE<sup>™</sup> plant extract is a
                                    highly effective soothing and antioxidant
                                    ingredient. In vitro studies prove that it
                                    has greater antioxidant boosting activity
                                    than other leading extracts including sage,
                                    green tea, black tea, Vitamin C and Vitamin
                                    E. It helps relieve redness of skin, repairs
                                    skin cell damaged by UV rays, and
                                    strengthens skin cell resilience to the
                                    sun*. Its efficacy has been proven by
                                    several years of extensive research,
                                    performed by the PIZ BUIN<sup>®</sup>{" "}
                                    brand’s parent company, Kenvue, with several patents covering its
                                    application in the skin care and cosmetic
                                    fields.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      Source: Parthenolide-depleted Feverfew
                                      (Tanacetum parthenium) protects skin from
                                      UV irradiation and external aggression,
                                      Arch Dermatol Res (2008) 300:69–80
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 1<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against cell alterations, decreases
                                    UVB-induced erythema and helps repair
                                    UV-damaged cells. Up to 60% improvement on
                                    UVB induced erythema and redness.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue publication
                                      in Arch Dermatol Res Feb 2008; 300 (2)
                                      69-80, Randomised, placebo-controlled,
                                      double blind study on 12 volunteers.
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 2<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against sunburn by stimulating natural
                                    processes that help maintain skin cell
                                    integrity* and strengthen their resilience
                                    to the sun.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue R&amp;D,
                                      Method: application of UV radiations on
                                      human skin ex plant and assessing the
                                      sunburn cells induced.
                                    </span>
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" alt="FEVERFEW PFE™" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLYCERINE
                                <div className="textBlock bottom">
                                  <p>
                                    Glycerine attracts water and holds it in the
                                    skin to help maintain a good hydration
                                    level.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" alt="GLYCERINE" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="products-page__product__details__where">
                        <a
                          href="/uk/where-to-buy/"
                          className="btn btn--uppercase"
                        >
                          Where To Buy
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="sun-lipstick" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-moisturising-sun-lipstick-aloe-vera.jpg" alt="Intensely moisturising sun protection" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SUN LIPSTICK</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          PIZ BUIN<sup>®</sup> MOISTURISING Sun Lipstick helps
                          to protect your lips from the harmful effects of UVA/
                          UVB exposure while providing them with intense
                          moisturisation.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN<sup>®</sup> MOISTURISING Sun Lipstick is
                            water resistant.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Read more
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>                        
                        <span className="factor factor30a" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" alt="ADVANCED UVA/UVB SUN FILTER SYSTEM" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                ALOE VERA
                                <div className="textBlock bottom">
                                  <p>
                                    Why is Aloe Vera so good?
                                    <br />
                                    Aloe Vera is a rich natural moisturiser with
                                    powerful soothing properties.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/006e44dceb4947a77acdd3948503af8d_f37.jpg" alt="ALOE VERA" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMIN E
                                <div className="textBlock bottom">
                                  <p>
                                    Vitamin E is a powerful natural antioxidant
                                    that helps to protect from free radicals,
                                    which damage skin cells* and cause premature
                                    skin ageing.
                                    <br />
                                    <span className="footNote">
                                      <br />* In vitro
                                      <br />
                                      <br />
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" alt="VITAMIN E" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="products-page__product__details__where">
                        <a
                          href="/uk/where-to-buy/"
                          className="btn btn--uppercase"
                        >
                          Where To Buy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected first"
                      href="/uk/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/ultra_light/" target="_self">
                      Ultra Light
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/onedaylong/" target="_self">
                      1 Day Long
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/all_products/" target="_self">
                      All products
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('.products-page__product__details__benefits__more').on('click', function () {\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif (box.is(':visible')) {\nbox.slideUp();\n$(this).text('Read More');\n} else {\nbox.slideDown();\n$(this).text('Read Less');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
