/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from "src/components/Gtm";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="uk" />
          <body className="our-products desktop" id />
          <meta charSet="UTF-8" />
          <meta name="Keywords" content />
          <meta
            content="width=device-width,initial-scale=1, minimum-scale=0.5, maximum-scale=1.0"
            name="viewport"
          />
          <title>Piz Buin</title>
          <link
            href="//fonts.googleapis.com/css?family=Open+Sans:400,800,700,600,300&subset=latin"
            rel="stylesheet"
            type="text/css"
          />
          <link
            href="//fast.fonts.net/cssapi/a23edeb8-5888-4291-b2f7-2b67a0b2708d.css"
            type="text/css"
            rel="stylesheet"
          />
          <link
            type="text/css"
            href="/assets/css/styles.css"
            rel="stylesheet"
          />
          <link
            type="text/css"
            href="/assets/css/vendor/iealert.css"
            rel="stylesheet"
          />
          <script
            type="text/javascript"
            innerHTML="
var lang = 'uk';
"
          />
          <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
          <link
            rel="alternate"
            hrefLang="en-Uk"
            href="https://www.pizbuin.com/uk/our-products/moisturising/"
          />
          <title>PIZ BUIN Moisturising - Intensely Moisturising Sun Protection.</title>
          <meta
            name="description"
            content="Advanced UVA/UVB sun protection filters help protect the skin from the sun and, combined with effective anti-oxidants, help prevent sun induced premature skin ageing."
          />
          <meta
            name="keywords"
            content="PIZ BUIN,
   pizbuin,
   piz-buin,
   sun,
   insun,
   in-sun,
   moisturising,
   moisturising_sun_lotion,
   moisturising_radiant_face_cream,
   moisturising_sun_lipstick,
   sun_protection,
   sunprotection,
   protection,
   sun_screen,
   sunscreen,
   tan tanning, 
   sun_lotion, 
   sun_cream, 
   sun_lipstick, 
   sunlotion, 
   suncream, 
   sunlipstick,"
          />
          
          <link
            rel="shortcut icon"
            href="/files/3014/3155/0941/favicon.ico"
            type="image/x-icon"
          />
          <link
            rel="icon"
            href="/files/3014/3155/0941/favicon.ico"
            type="image/x-icon"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/css/vendor/ccm.base.css"
          />
          <script type="text/javascript" src="/assets/js/vendor/jquery.js" />  

<script type="text/javascript" src="/assets/js/vendor/jquery-migrate-3.0.0.min.js" />
          <script type="text/javascript" src="/assets/js/vendor/ccm.base.js" />
          <script type="text/javascript">
function OptanonWrapper() { }
</script>
          <link
            rel="stylesheet"
            type="text/css"
            href="/assets/css/vendor/flex-slider.css"
          />
          <script
            type="text/javascript"
            src="/assets/js/vendor/jquery.flexslider-min.js"
          />
          <style
            type="text/css"
            cssText="
   #blockStyle379Slider59 {
   background-repeat: no-repeat;
   }
"
          />
          
        </Helmet>
        <Gtm />
        ;
      </React.Fragment>
    );
  }
}
